<template>
    <div class="dashboard">
        <v-system-bar dark color="primary" app>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="tertiary" dark v-bind="attrs" v-on="on">
                        mdi mdi-location-exit
                    </v-icon>
                </template>
                <span>Cerrar sesión</span>
            </v-tooltip>
        </v-system-bar>
        <v-app-bar app color="secondary" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>SP Eudania</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon>mdi mdi-email-outline</v-icon>
            </v-btn>

            <v-btn icon>
                <v-icon>mdi mdi-account</v-icon>
            </v-btn>

            <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-card>
                <v-img contain dark height="100%" src="@/assets/images/eudiana_tech.jpg">

                </v-img>
                <v-divider></v-divider>

                <v-list class="list-nav-text text-left" dense nav two-line>
                    <v-list-item-group v-model="group" active-class="gold--text text--accent-4">
                        <v-list-item link :to="l.link" v-for="(l, i) in links" :key="i">
                            <v-list-item-icon>
                                <v-icon color="primary">
                                    {{ l.icon }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ l.name }}</v-list-item-title>
                                <v-list-item-subtitle> {{ l.subtitle }} </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>


                    </v-list-item-group>
                </v-list>
                <v-spacer></v-spacer>
            </v-card>

        </v-navigation-drawer>
        <v-main>
            <v-container>
                <transition name="fade-transform" mode="out-in">
                    <router-view></router-view>
                </transition>
            </v-container>
        </v-main>
    </div>
</template>
<script>
export default {
    data: () => ({
        drawer: false,
        group: null,
        links: [
            { name: 'Inicio', title: 'Inicio', subtitle: '', icon: 'mdi-home', link: '/' },
            { name: 'Bases', title: 'Zonas cubiertas', subtitle: 'Clientes', icon: 'mdi mdi-shield-car', link: '/operation_bases' },
            { name: 'Servicios', title: 'Servicios', subtitle: 'Inversión y financiamiento', icon: 'mdi-tools', link: '/services' },
            { name: 'Proyectos de Inversión', title: 'Proyectos de Inversión', subtitle: 'Modalidades de inversión', icon: 'mdi-handshake', link: '/projects' },
            { name: 'Contacto', title: 'Contacto', subtitle: 'Nuestros expertos le responderán muy pronto.', icon: 'mdi-face-agent', link: '/contact' }
        ],
    }),
    watch: {
        group() {
            this.drawer = false
        },
    },
}
</script>
<style>
.dashboard {
    height: 100%;
    width: 100%;
}
</style>